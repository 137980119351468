import React, { useState, useEffect } from "react";
import "./NewsAndEvents.css";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import Loader from "../../UI Helpers/Simple Loading/Loader";
import { useSelector } from "react-redux";
import { checkIfArrayIsEmpty, getDateFormatted } from "../../MasterComponents/CommonFunctionalities";
import UserAttendenceChart from "../UserAttendenceChart/UserAttendenceChart";
import UserMarksAttendanceChart from "../UserMarksAttendanceChart/UserMarksAttendanceChart";
import { getA2CourseThumbnail } from "../../UI Helpers/CourseThumnailMapper/A2CourseThumnailMapper";

const NewsAndEvents = ({ changeScreenFunc }) => {
  const [courses, setCourses] = useState([]);
  const [onlineClasses, setOnlineClass] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const userLoginStatus = useSelector((state) => state.userLogin);

  const enrolledCoursesIds = userLoginStatus.userInfo?.enrolled_courses;

  const apiCall = new CommonCalls();

  const getEnrolledCourses = async () => {
    if (checkIfArrayIsEmpty(enrolledCoursesIds)) {
      setLoading(false); 
      return;
    }
    setLoading(true);
    const response = await apiCall.getEnrolledCourseById(enrolledCoursesIds);
    if (response) {
      setError(false);
      setLoading(false);
      return response;
    } else {
      setLoading(false);
      setError("Could not load data from server");
      return;
    }
  };
  const getOnlineClasses = async () => {
    setLoading(true);
    const response = await apiCall.getUserOnlineClasses();
    if (response) {
      setLoading(false);
      return response;
    } else {
      setLoading(false);
      return;
    }
  };
  

  useEffect(() => {
    const getApiData = async () => {
      const enrolledCoursesResponse = await getEnrolledCourses();
      const onlineClassesdata = await getOnlineClasses();
      
      if (onlineClassesdata?.status == 200) {
        setOnlineClass(onlineClassesdata?.data.slice(-2));
        
      }
      if (!error) {
        setCourses(enrolledCoursesResponse?.slice(-2));
      }
    };

    getApiData();
  }, []);

  return (
    <div className="news-events-container">
      <div className="row gy-4">
        {/* <div className="col-lg-6">
          <h3 className="h1_main">News & Events</h3>
          <div className="section news-events">
            <div className="news-event-item">
              <div className="date">
                <div className="">May 16</div>
                <div className="date-text">2024</div>
              </div>
              <div className="content">
                <p>Unblocking of LMS accounts for spring 2024</p>
                <span>News Updates</span>
              </div>
            </div>
            <div className="news-event-item">
              <div className="date">
                <div className="">May 16</div>
                <div className="date-text">2024</div>
              </div>
              <div className="content">
                <p>Unblocking of LMS accounts for spring 2024</p>
                <span>News Updates</span>
              </div>
            </div>
            <div className="news-event-item">
              <div className="date">
                <div className="">May 16</div>
                <div className="date-text">2024</div>
              </div>
              <div className="content">
                <p>Unblocking of LMS accounts for spring 2024</p>
                <span>News Updates</span>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-lg-6">
          <h3 className="h1_main">
            Enrolled Courses{" "}
            <span
              className="see-more"
              onClick={() => {
                changeScreenFunc(1);
              }}
            >
              See More
            </span>
          </h3>
          {loading ? (
            <Loader />
          ) : 
          <>
            {
              courses ?
              
              (
                <div className="enrolledCourseSection section" style={{boxShadow:"none"}}>
                  {courses?.map((course, index) => {
                    return (
                      <div className="section enrolled-courses ">
                        <div className="course-item">
                          <img
                            src={getA2CourseThumbnail(course?.course_id,"/images/enrolled-courses-icon.png")}
                            alt="Course Thumbnail"
                          />
                          <div className="course-info">
                            <p className="teacher-name">Moosa Khan</p>
                            <p className="course-name">{course?.course_name}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )
              :
                (
                  <div className="d-flex flex-column align-items-center justify-content-center h-100 py-5">
                    <h6 className="text-center">No courses are currently enrolled. <span onClick={() => {
                        changeScreenFunc(2);
                      }} style={{cursor:'pointer',color:"#007DFF"}}>Click here</span> to explore and purchase courses.</h6>
                  </div>
                )
            }
          </>
          }
        </div>

        <div className="col-lg-6">
          <h3 className="h1_main">
            Marks & Attendance{" "}
            <span
              className="see-more"
              onClick={() => {
                changeScreenFunc(6);
              }}
            >
              See More
            </span>
          </h3>
         
            <>
              
                  <div className="section marks-attendance">
                     <UserAttendenceChart dashboardDisplay={true}/>
                     <UserMarksAttendanceChart dashboardDisplay={true}/>
                  </div>
            </>
         
        </div>
        <div className="col-lg-6">
          <h3 className="h1_main">
            Upcoming Online Class{" "}
            <span
              className="see-more"
              onClick={() => {
                changeScreenFunc(7);
              }}
            >
              See More
            </span>
          </h3>
          {onlineClasses.length > 0 ? (
            <>
              {onlineClasses.map((classdetail) => {
                return (
                  <div className="section upcoming-online-class">
                    <div
                      className="online-class-item "
                      key={classdetail?.class_id}
                    >
                      <div className="class-info">
                        <p className="subject-name">
                          {classdetail?.class_topic}
                        </p>
                        <p className="class-date-time">
                          Class Date & Time:{" "}
                          <span className="time-date">
                            {getDateFormatted(classdetail?.session_date_time)}
                          </span>{" "}
                        </p>
                      </div>
                      <img
                        style={{ cursor: 'pointer'}}
                        src="/images/icons/zoom-logo-new.png"
                        alt="Zoom Icon"
                        className="zoom-icon"
                        onClick={() => {
                          window.open(classdetail?.zoom_live_link);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          ):
          (
            <div className="d-flex align-items-center justify-content-center h-100 py-5">
              <h6>No online classes found yet</h6>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsAndEvents; 