export const getA2CourseThumbnail = (courseId,defaulImg="/images/courses-box-banner1.png") => {
    let imgUri = defaulImg;
  
    switch (courseId) {
      case 55:
        imgUri = "/images/creatives/A2Coursethumbs/1-energetics.jpg";
        break;
      case 56:
        imgUri = "/images/creatives/A2Coursethumbs/2-ionic.jpg";
        break;
      case 57:
        imgUri = "/images/creatives/A2Coursethumbs/3-electrochemistry.jpg";
        break;
      case 58:
        imgUri = "/images/creatives/A2Coursethumbs/4-reaction.jpg";
        break;
      case 71:
        imgUri = "/images/creatives/A2Coursethumbs/5-organic.jpg";
        break;
      case 72:
        imgUri = "/images/creatives/A2Coursethumbs/7-transition.jpg";
        break;
      case 73:
        imgUri = "/images/creatives/A2Coursethumbs/6-paper.jpg";
        break;
        // AS courses check
        case 59:
          imgUri = "/images/creatives/AsCourseThumbs/atomicstructure.jpg";
          break;
        case 60:
          imgUri = "/images/creatives/AsCourseThumbs/chemicalbonding.jpg";
          break;
        case 62:
          imgUri = "/images/creatives/AsCourseThumbs/moles.jpg";
          break;
        case 74:
          imgUri = "/images/creatives/AsCourseThumbs/stateofmatter.jpg";
          break;
        // case 75:
        //   imgUri = "/images/creatives/AsCourseThumbs/redoxreaction.jpg";
        //   break;
        // case  76:
        //   imgUri = "/images/creatives/AsCourseThumbs/reactionkinetics.jpg";
        //   break;
        case 77:
          imgUri = "/images/creatives/AsCourseThumbs/energetics.jpg";
          break;
        case 78:
          imgUri = "/images/creatives/AsCourseThumbs/chemicalequllibrium.jpg";
          break;
        case 79:
          imgUri = "/images/creatives/AsCourseThumbs/preodicinorganicchemistry.jpg";
          break;
        case 80:
          imgUri = "/images/creatives/AsCourseThumbs/group2.jpg";
          break;
        case 81:
          imgUri = "/images/creatives/AsCourseThumbs/group71inorganicchemistry.jpg";
          break;
        case 82:
          imgUri = "/images/creatives/AsCourseThumbs/nitrogenandsulphur.jpg";
          break;
        case 83:
          imgUri = "/images/creatives/AsCourseThumbs/organicandanalyticschemistry.jpg";
          break;

      default:
        imgUri =defaulImg;
    }
  
    return imgUri;
  };