import React from 'react';
import './Courses.css';
import Settings from '../CommonAPICalls/Settings';
import { useNavigate } from 'react-router-dom';
import { getA2CourseThumbnail } from '../UI Helpers/CourseThumnailMapper/A2CourseThumnailMapper';

const Courses = ({typeSpecificCourse}) => {
  const navigate = useNavigate()

  return (
    <>
      <div className="container courses-card mt-5">
        {
          typeSpecificCourse.map((course)=>{
            return (
              <div className="card mb-5">
                <div className="row no-gutters">
                  <div className="col-lg-6">
                    <img
                      src={`${getA2CourseThumbnail(course?.course_id)}`}
                      className="card-img"
                      alt="Particle Chemistry"
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="card-body">
                      <h5 className="h2_main">{Settings.enumTypes.find(id => id.value === course?.course_type_id)?.label}</h5>
                      <h6 className="h1_main">{course?.course_name}</h6>
                      <p className="para_main">
                        {course?.course_description}
                      </p>
                      <div className='d-flex flex-column flex-lg-row align-items-lg-center align-items-start justify-content-lg-between justify-content-center course-content mb-3'>
                        <div className="d-flex align-items-center  mb-0">
                          <img src="/images/Group-2042.svg" alt="Icon 1" />
                          <h6 className="para_course_main">Chemistry</h6>
                          <img
                            src="/images/course-icon2.svg"
                            alt="Icon 2"
                            className="courses-img"
                          />
                          <h6 className="para_course_main">{Settings.enumCourseLevels.find(id => id.value === course?.course_level)?.label}</h6>
                        </div>
                        {
                          course?.discount ?
                          <div className='d-flex align-items-center justify-content-end gap-3 ps-lg-3 ps-0 mb-0 mt-lg-0 mt-3'>
                          <h3 className='price_text cut_red '>PKR {course?.course_price}/-</h3>
                          <h3 className='price_text '>PKR {course?.discounted_amount}/-</h3>
                          </div>
                          :
                          <h3 className='price_text ps-lg-3 ps-0 mb-0 mt-lg-0 mt-3'>PKR {course?.course_price}/-</h3>
                        }
                      </div>
                      <p onClick={()=>{navigate(`/courses/course-preview/${course?.slug_url}`)}}>
                        <button className="connect-button">
                          Get Enrolled Now
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }
        

        {/* <div className="card mb-5">
          <div className="row no-gutters">
            <div className="col-lg-6">
              <img
                src="/images/courses-box-banner2.png"
                className="card-img"
                alt="Particle Chemistry"
              />
            </div>
            <div className="col-lg-6">
              <div className="card-body">
                <h5 className="h2_main">AS Level</h5>
                <h6 className="h1_main">AS Level – Particle Chemistry</h6>
                <p className="para_main">
                  Convallis vivamus at cras porta nibh velit aliquam eget in faucibus mi tristique aliquam ultrices sit cras nascetur
                </p>
                <div className="d-flex align-items-center course-content mb-3">
                  <img src='/images/course-icon1.svg' alt="Icon 1" />
                  <h6 className='para_course_main'>7 Lessons</h6>
                  <img src='/images/course-icon2.svg' alt="Icon 2" className='courses-img' />
                  <h6 className='para_course_main'>Intermediate</h6>
                </div>
                <a href="/courses/course-preview">
  <button className='connect-button'>Get Enrolled Now</button>
</a>

              </div>
            </div>
          </div>
        </div>

        <div className="card mb-5">
          <div className="row no-gutters">
            <div className="col-lg-6">
              <img
                src="/images/courses-box-banner3.png"
                className="card-img"
                alt="Particle Chemistry"
              />
            </div>
            <div className="col-lg-6">
              <div className="card-body">
                <h5 className="h2_main">AS Level</h5>
                <h6 className="h1_main">AS Level – Particle Chemistry</h6>
                <p className="para_main">
                  Convallis vivamus at cras porta nibh velit aliquam eget in faucibus mi tristique aliquam ultrices sit cras nascetur
                </p>
                <div className="d-flex align-items-center course-content mb-3">
                  <img src='/images/course-icon1.svg' alt="Icon 1" />
                  <h6 className='para_course_main'>7 Lessons</h6>
                  <img src='/images/course-icon2.svg' alt="Icon 2" className='courses-img' />
                  <h6 className='para_course_main'>Intermediate</h6>
                </div>
                <a href="/courses/course-preview">
  <button className='connect-button'>Get Enrolled Now</button>
</a>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-5">
          <div className="row no-gutters">
            <div className="col-lg-6">
              <img
                src="/images/courses-box-banner2.png"
                className="card-img"
                alt="Particle Chemistry"
              />
            </div>
            <div className="col-lg-6">
              <div className="card-body">
                <h5 className="h2_main">AS Level</h5>
                <h6 className="h1_main">AS Level – Particle Chemistry</h6>
                <p className="para_main">
                  Convallis vivamus at cras porta nibh velit aliquam eget in faucibus mi tristique aliquam ultrices sit cras nascetur
                </p>
                <div className="d-flex align-items-center course-content mb-3">
                  <img src='/images/course-icon1.svg' alt="Icon 1" />
                  <h6 className='para_course_main'>7 Lessons</h6>
                  <img src='/images/course-icon2.svg' alt="Icon 2" className='courses-img' />
                  <h6 className='para_course_main'>Intermediate</h6>
                </div>
                <a href="/courses/course-preview">
  <button className='connect-button'>Get Enrolled Now</button>
</a>
              </div>
            </div>
          </div>
        </div> */}
        
      </div>
    </>
  );
};

export default Courses;
